import * as React from "react";
import { useRef, useEffect, useState } from "react";
import { Box, Typography, Grid, Divider, Button } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion } from 'framer-motion';

import {
  Text
} from "@chakra-ui/react";

// Custom components
import Card from "./card";
import ContactForm from "./contactForm";

// Images and other files
import VillaImg from "../../resources/images/external/luxusvilla-mit-pool.jpg";
import BackgroundImg from "../../resources/images/generated/background-white-v1.png";
import CustomizeImg from "../../resources/images/external/customized-pool-img.jpg";
import QualityImg from "../../resources/images/external/interior-design-quality.jpg";
import ServiceImg from "../../resources/images/external/geschaeftsleute-haende-auf-weissem-tisch-mit-dokumenten-und-entwuerfen.jpg";
import ThumbsUpImage from "../../resources/images/external/nahaufnahme-maenner-die-daumen-hochhalten.jpg";

import exklusivHausprojektImg from "../../resources/images/external/partners/ExklusixHausprojektLogo-New.jpg";
import gigaArchitekciImg from "../../resources/images/external/partners/giga-architekci.png";
import diekmeierInvestmentImg from "../../resources/images/external/partners/Diekmeier-Investment.PNG";
import golankoImmobilienImg from "../../resources/images/external/partners/golanko-immobilien.PNG";

const Home = (props) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [scrollHeight, setScrollHeight] = useState("1080px");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [shownCardsCount, setShownCardsCount] = useState(3);
  const [showNotification, setShowNotification] = useState(false);
  const [targetProjectIndex, setTargetProjectIndex] = useState(null);
  const [cardLG, setCardLG] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
    threshold: 0.1,    // Trigger when 10% of the component is visible
  });
  var body = document.body,
    html = document.documentElement;

  useEffect(() => {
    let tempArr = [];
    props.data.projects.forEach((elem) => {
      tempArr.push(6);
    });
    setCardLG(tempArr);
  }, [props.data.projects]);

  useEffect(() => {
    setIsSmallScreen(windowSize.current[0] < 1200); // width >900
  }, [windowSize]);

  useEffect(() => {
    setScrollHeight(Math.max(body.offsetHeight, html.offsetHeight));
  }, [body.offsetHeight, html.offsetHeight, cardLG]);

  useEffect(() => {
  const availableProjectIndex = props.data.projects.findIndex(
    (project) => project.status.toLowerCase() === "im bau"
  );
  if (availableProjectIndex !== -1) {
    setShowNotification(true);
    setTargetProjectIndex(availableProjectIndex);
  }
  }, [props.data.projects]);


  return (
    <Grid container style={{ marginTop: "0em", paddingTop: "80px", display: "flex" }}>
    {showNotification && (
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "fixed",
          top: "100px",
          right: "10px",
          width: isSmallScreen ? "320px" : "400px", // Responsive width
          background: "linear-gradient(135deg, #1E90FF, #4682B4)", // Blue gradient
          color: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
        }}
      >
        {/* Project Image */}
        <img
          src={props.data.projects[targetProjectIndex].titleImg}
          alt="Projektbild"
          style={{
            width: "100%",
            borderRadius: "8px",
            marginBottom: "15px",
            objectFit: "cover",
          }}
        />

        {/* Notification Title */}
        <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Ein wunderschönes EFH in Berlin Heiligensee ist bald für Sie verfügbar!
        </Typography>

        {/* Notification Description */}
        <Typography variant="body2" style={{ marginBottom: "15px" }}>
          Das EFH befindet sich im Bau und ist ab Januar 2025 bereit für Sie.
        </Typography>

        {/* Close Button */}
        <Button
          onClick={() => setShowNotification(false)}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            backgroundColor: "transparent",
            color: "white",
            fontSize: "16px",
            minWidth: "auto",
            padding: "0 5px",
            border: "none",
            cursor: "pointer",
          }}
        >
          ✕
        </Button>

        {/* Action Button */}
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "white",
            color: "black",
            textTransform: "none",
            fontWeight: "bold",
            width: "100%",
          }}
          onClick={() => {
            setShowNotification(false);
            const targetElement = document.getElementById(`project-${targetProjectIndex}`);
            if (targetElement) {
              const navbarHeight = document.querySelector('nav')?.offsetHeight || 0; // Adjust for fixed navbar height
              const additionalOffset = 80; // Add any extra spacing to avoid overscrolling
              const scrollToPosition = targetElement.getBoundingClientRect().top + window.scrollY - navbarHeight - additionalOffset;

              window.scrollTo({
                top: scrollToPosition,
                behavior: "smooth",
              });
            }
          }}
        >
          Zeige mir mehr
        </Button>
      </motion.div>
    )}


      <Grid item xs={12} md={12} alignItems={"center"} alignContent={"center"}>
        <Grid
          container
          style={{ marginTop: "0em" }}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item style={{ height: isSmallScreen ? "2vh" : "3vh", }}></Grid>
          <Grid
            item
            style={{
              backgroundColor: "white", // Changed to white
              padding: isSmallScreen ? "1.5em" : "3em",
              borderRadius: "3px",
              width: isSmallScreen ? "100%" : "80%",
              color: "black", // Changed text color to black
            }}
          >
            <Grid container spacing={3}>
              {props.missionRef && (
                <Grid item id="mission" xs={12}>
                  <Text color="black" variant="h6" ref={props.missionRef} fontSize={"26pt"}>
                    Mission
                  </Text>
                  <Divider
                    style={{
                      backgroundColor: "lightgrey",
                      margin: "5px 0px 20px 0px",
                    }}
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Text
                        color="black"
                        variant="h4"
                        marginBottom={"0.5em"}
                        fontSize={"22pt"}
                      >
                        Unsere Mission: Ein Zuhause, in dem Sie sich
                        verwirklichen können
                      </Text>
                      <Text color="black" variant="body1" fontSize={"15pt"}>
                        Wir sind stolz darauf, Ihnen eine umfassende Auswahl an
                        hochwertigen Immobilien anzubieten, die den höchsten
                        Ansprüchen an Qualität, Stil und Komfort gerecht werden.
                        Als Bauunternehmen im Luxussegment verfügt unser Team
                        über langjährige Erfahrung und Expertise in der Planung,
                        Entwicklung und Realisierung von Luxusimmobilien für
                        unsere anspruchsvollen Kunden im Umkreis von Berlin.
                      </Text>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <motion.img
                          whileHover={{ scale: 1.05 }}
                          transition={{ duration: 0.3 }}
                          src={VillaImg}
                          alt={"Villa"}
                          loading="lazy"
                          style={{ width: "100%", cursor: 'pointer' }}
                      />
                      <small style={{ color: "white" }}>Bild von benzoix auf Freepik</small>
                    </Grid>
                    {isSmallScreen && (
                      <Divider
                        style={{
                          backgroundColor: "lightgrey",
                          margin: "5px 0px 20px 10px",
                          width: "100%",
                        }}
                      />
                    )}
                    <Grid item xs={12} lg={4}>
                      {" "}
                      <img
                        src={QualityImg}
                        alt="Construction"
                        width="100%"
                      ></img>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <Text color="black" variant="h4" fontSize={"22pt"}>
                        Höchster Anspruch an Qualität
                      </Text>
                      <Text color="black" variant="body1" fontSize={"15pt"}>
                        Unsere Projekte umfassen exklusive Villen, private
                        Residenzen, luxuriöse Apartments und innovative
                        Wohnkonzepte. Wir setzen auf individuelle Architektur,
                        die sich nahtlos in die Umgebung einfügt und dabei
                        höchste Funktionalität und Ästhetik vereint. Dabei legen
                        wir besonderen Wert auf die Auswahl erstklassiger
                        Materialien und modernster Technologie, um höchste
                        Standards in puncto Nachhaltigkeit, Energieeffizienz und
                        Wohngesundheit zu erreichen.
                      </Text>
                    </Grid>
                    {isSmallScreen && (
                      <Divider
                        style={{
                          backgroundColor: "lightgrey",
                          margin: "25px 0px 0px 10px",
                          width: "100%",
                        }}
                      />
                    )}
                    <Grid item xs={12} lg={8}>
                      <Text color="black" variant="h4" fontSize={"22pt"}>
                        Maßgeschneiderte Projekte
                      </Text>
                      <Text color="black" variant="body1" fontSize={"15pt"}>
                        Unsere Gesellschaft setzt auf maßgeschneiderte Projekte
                        anstelle von Massenfertigung. Sie bieten entscheidende
                        Vorteile im Vergleich zu Standard-Häusern, die uns
                        helfen, ihren Traum nach einem perfekten Eigenheim zu
                        verwirklichen:
                        <br />
                        <br />
                        1) Personalisierung: Das offensichtlichste und
                        wichtigste Merkmal maßgeschneiderten Projekten ist die
                        Möglichkeit, das Haus an Ihre spezifischen Bedürfnisse
                        und Vorlieben anzupassen. Sie können den Grundriss, die
                        Anzahl der Zimmer, die Größe der Zimmer, die Materialien,
                        die Farben und vieles mehr auswählen, um ein Haus zu
                        schaffen, das perfekt zu Ihren Bedürfnissen und Ihrem
                        Lebensstil passt.
                        <br />
                        <br />
                        2) Bessere Qualität: Maßgeschneiderte Häuser werden in
                        der Regel mit hochwertigeren Materialien gebaut und von
                        erfahrenen Handwerkern und Bauunternehmern gebaut. Das
                        bedeutet, dass Sie ein Haus erhalten, das besser gebaut
                        ist und länger hält als ein Standard-Haus.
                        <br />
                        <br />
                        3) Mehr Zufriedenheit: Letztendlich kann ein
                        maßgeschneidertes Haus dazu beitragen, dass Sie sich in
                        Ihrem Zuhause wohler und zufriedener fühlen, da es auf
                        Ihre spezifischen Bedürfnisse und Vorlieben zugeschnitten
                        ist. Sie können sich entspannen und das Haus genießen,
                        weil es für Sie gebaut wurde.
                      </Text>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <br />
                      <br />
                      <img
                        src={CustomizeImg}
                        alt="Construction"
                        width="100%"
                      ></img>{" "}
                      <small style={{ color: "white" }}>Bild von Freepik</small>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    {isSmallScreen && (
                      <Divider
                        style={{
                          backgroundColor: "lightgrey",
                          margin: "5px 0px 20px 10px",
                          width: "100%",
                        }}
                      />
                    )}
                    <Grid item xs={12} lg={4}>
                      <img
                        src={ServiceImg}
                        alt="Construction"
                        width="100%"
                      ></img>
                    </Grid>

                    <Grid item xs={12} lg={8}>
                      <Text color="black" variant="h4" fontSize={"22pt"}>
                        Bester Service
                      </Text>
                      <Text color="black" variant="body1" fontSize={"15pt"}>
                        Unser erfahrenes Team von Architekten, Designern und
                        Bauexperten steht Ihnen von der ersten Beratung bis zur
                        Fertigstellung Ihres Projekts mit umfassendem Know-how
                        und persönlichem Engagement zur Seite. Wir arbeiten eng
                        mit unseren Kunden zusammen, um ihre individuellen
                        Wünsche und Bedürfnisse zu erfüllen und jedes Projekt zu
                        einem einzigartigen Erlebnis zu machen. Wir setzen alles
                        daran, Ihr Traumhaus zu verwirklichen und Ihnen ein
                        Zuhause zu schaffen, in dem Sie sich rundum wohl fühlen.
                        Wir laden Sie herzlich ein, uns kennenzulernen und
                        gemeinsam mit uns Ihr Traumhaus zu planen und zu bauen.
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Divider
            style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }}
          />
          <Grid
            item
            style={{
              backgroundColor: "white", // Changed to white
              padding: "3em",
              borderRadius: "3px",
              width: isSmallScreen ? "100%" : "80%",
              color: "black", // Changed text color to black
            }}
          >
            <Grid container spacing={3}>
              {props.projectsRef && (
                <>
                  <Grid item id="projects" xs={12}>
                    <Text color="black" variant="h6" ref={props.projectsRef} fontSize={"26pt"}>
                      Projekte
                    </Text>
                    <Divider
                      style={{
                        backgroundColor: "lightgrey",
                        margin: "5px 0px 20px 0px",
                      }}
                    />
                  </Grid>
                  {cardLG &&
                    props.data["projects"].map((project, idx) => {
                    if (idx <= shownCardsCount) {
                      return (
                        <Grid
                          key={idx}
                          id={`project-${idx}`}
                          item
                          xs={12}
                          lg={cardLG[idx] ? cardLG[idx] : 6}
                        >
                          <Card
                            project={project}
                            setCardLG={setCardLG}
                            cardLG={cardLG}
                            idx={idx}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                  <Grid item id="prject-buttons" xs={12}>
                    {props.data["projects"].length < shownCardsCount && (
                      <Button
                        variant="outlined"
                        onClick={(e) => setShownCardsCount(shownCardsCount + 4)}
                      >
                        Zeige mehr
                      </Button>
                    )}
                    {props.data["projects"].length > 3 && shownCardsCount > 3 && (
                      <Button
                        variant="outlined"
                        onClick={(e) => setShownCardsCount(3)}
                      >
                        Verstecken
                      </Button>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Divider
            style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }}
          />
          <Grid
            item
            style={{
              backgroundColor: "white", // Changed to white
              padding: "3em",
              borderRadius: "3px",
              width: isSmallScreen ? "100%" : "80%",
              color: "black", // Changed text color to black
            }}
          >
            <Grid container spacing={3}>
              {props.teamRef && (
                <>
                  <Grid item id="mission" xs={12}>
                    <Text color="black" variant="h6" ref={props.teamRef} fontSize={"26pt"}>
                      Team
                    </Text>
                    <Divider
                      style={{
                        backgroundColor: "lightgrey",
                        margin: "5px 0px 20px 0px",
                      }}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={7}>
                        <Text
                          color="black"
                          variant="h4"
                          marginBottom={"0.5em"}
                          fontSize={"22pt"}
                        >
                          Unser Team: Ein Verbund für die Exzellenz
                        </Text>
                        <Text
                          color="black"
                          variant="body1"
                          fontSize={"15pt"}
                        >
                          Das Team bestehend aus der BDG Wohnbau GmbH und Ihren
                          Partnern zeichnet sich durch hervorragende Leistungen
                          und außergewöhnliche Qualität aus. Die Expertise und
                          das Engagement jedes einzelnen Mitglieds ermöglichen
                          es dem Unternehmen, Projekte jeder Größe und
                          Komplexität erfolgreich abzuschließen. Das Team
                          verfügt über umfangreiche Erfahrung in allen Bereichen
                          des Bauwesens, von der Planung und Gestaltung bis hin
                          zur Ausführung und Fertigstellung. Das Unternehmen hat
                          sich einen erstklassigen Ruf erworben, indem es seine
                          Arbeit stets mit höchster Professionalität und
                          Effizienz durchführt. Kunden können sich darauf
                          verlassen, dass unser Team ihre Erwartungen nicht nur
                          erfüllt, sondern sogar übertrifft. Mit ihrer
                          Leidenschaft für Qualität und Exzellenz sind sie stolz
                          darauf, jedes Projekt mit höchster Sorgfalt und
                          Präzision abzuschließen.
                        </Text>
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <img
                          src={ThumbsUpImage}
                          alt="Villa"
                          style={{ width: "100%" }}
                        ></img>
                        <small style={{ color: "white" }}>Bild von Freepik</small>
                      </Grid>
                      {isSmallScreen && (
                        <Divider
                          style={{
                            backgroundColor: "lightgrey",
                            margin: "5px 0px 20px 0px",
                            width: "100%",
                          }}
                        />
                      )}
                      <Grid item xs={12} lg={12}>
                        <Text
                          color="black"
                          variant="h4"
                          fontSize={"22pt"}
                          marginBottom={"0.5em"}
                        >
                          Mit unseren Partnern zum Erfolg
                        </Text>
                        <Text
                          color="black"
                          variant="body1"
                          fontSize={"15pt"}
                        >
                          Das exzellente Partnernetzwerk ist eine unserer
                          Stärken: Wir haben sorgfältig ein Netzwerk von Partnern
                          aufgebaut, die Experten in verschiedenen Bereichen des
                          Bauwesens sind. Unsere Partner haben sich durch
                          jahrelange Erfahrung und Fachkenntnisse in ihrem
                          jeweiligen Bereich ausgezeichnet. Das Partnernetzwerk
                          der BDG Wohnbau GmbH umfasst Architekten, Ingenieure,
                          Wirtschaftsingenieure, Subunternehmer, Lieferanten und
                          andere wichtige Akteure in der Baubranche. Jeder
                          Partner wird sorgfältig ausgewählt und überprüft, um
                          sicherzustellen, dass er den hohen Standards unserer
                          Gesellschaft entspricht. Durch die Zusammenarbeit mit
                          einem breiten Spektrum an Partnern können wir eine
                          Vielzahl von Leistungen anbieten und eine große
                          Bandbreite an Projekten erfolgreich durchführen. Die
                          Partnerschaften ermöglichen auch eine nahtlose
                          Koordination und Kommunikation zwischen den
                          verschiedenen Akteuren, was zu einer reibungslosen und
                          effizienten Durchführung von Projekten führt. Die
                          größte Stärke unseres Partnernetzwerks liegt in der
                          Fähigkeit, die besten Talente und Experten der Branche
                          zusammenzubringen, um ein gemeinsames Ziel zu
                          erreichen - höchste Qualität und Kundenzufriedenheit.
                        </Text>
                        <br />
                        <Text color="black" variant="body1" fontSize={"15pt"}>
                          <b>Zu unseren Partnern zählen mitunter:</b>
                        </Text>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justify="center"
                        alignItems="center"
                        style={{ margin: "1em 0px" }}
                      >
                        <Grid item xs={12} lg={3} align="center">
                          <img
                            src={diekmeierInvestmentImg}
                            alt="Villa"
                            style={{ width: "100%" }}
                          ></img>
                          <Text color="black">
                            <b>Verwaltung und Finanzierung</b>
                          </Text>
                        </Grid>
                        <Grid item xs={12} lg={3} align="center">
                          <img
                            src={gigaArchitekciImg}
                            alt="Villa"
                            style={{ width: "100%" }}
                          ></img>
                          <Text color="black">
                            <b>Architektur</b>
                          </Text>
                        </Grid>

                        <Grid item xs={12} lg={3} align="center">
                          <img
                            src={golankoImmobilienImg}
                            alt="Villa"
                            style={{ width: "100%" }}
                          ></img>
                          <Text color="black">
                            <b>Vermarktung und Vertrieb</b>
                          </Text>
                        </Grid>
                        <Grid item xs={12} lg={3} align="center">
                          <img
                            src={exklusivHausprojektImg}
                            alt="Villa"
                            style={{ width: "100%" }}
                          ></img>
                          <Text color="black">
                            <b>Bauplanung und Umsetzung</b>
                          </Text>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Divider
            style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }}
          />
          <Grid
            item
            style={{
              backgroundColor: "white", // Changed to white
              padding: "3em",
              borderRadius: "3px",
              width: isSmallScreen ? "100%" : "80%",
              color: "black", // Changed text color to black
            }}
          >
            <Grid container spacing={3}>
              {props.contactRef && (
                <>
                  <Grid item id="mission" xs={12}>
                    <Text color="black" variant="h6" ref={props.contactRef} fontSize={"26pt"}>
                      Kontakt
                    </Text>
                    <Divider
                      style={{
                        backgroundColor: "lightgrey",
                        margin: "5px 0px 20px 0px",
                      }}
                    />
                    <ContactForm isSmallScreen={isSmallScreen}></ContactForm>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundImage: `url('${BackgroundImg}')`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "100%",
          opacity: 0.4,
          width: "100%",
          height: scrollHeight,
          position: "absolute",
          zIndex: "-1",
        }}
      ></div>
      {/* <img src={ExampleHomeImg} alt="House Construction" style={{ backgroundImage: `url(${ExampleHomeImg})`, opacity: 0.4, width: "100%", position: "absolute", zIndex: "-1" }}></img> */}
    </Grid>
  );
};

export default Home;

