import * as React from "react";
import {
  Box,
  Grid,
  FilledInput,
  Typography,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState, useRef } from "react";

import emailjs from "@emailjs/browser";

export default function ContactForm(props) {
  const [email, setEmail] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [showConsentDialog, setShowConsentDialog] = useState(false); // Popup state
  const eMailRef = useRef(null);
  const nameRef = useRef(null);
  const telRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setShowSuccess(false);
      setShowFailure(false);
    }, 10000);
  }, [showSuccess, showFailure]);

  const handleSendClick = (e) => {
    e.preventDefault();
    setShowConsentDialog(true); // Show consent dialog before submission
  };

  const handleConsentAgree = () => {
    setShowConsentDialog(false);
    let formData = {
      from_name: nameRef.current.value,
      from_email: eMailRef.current.value,
      from_tel: telRef.current.value,
      message: textRef.current.value,
    };
    emailjs
      .send("service_y76xw9f", "template_llqdr1i", formData, "7eXZ-We0hTjg6bmmp")
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setShowFailure(true);
        }
      );
  };

  const handleConsentCancel = () => {
    setShowConsentDialog(false);
  };

  return (
    <Box color="black">
      {showSuccess && (
        <Alert severity="success">
          Vielen Dank! Wir haben Ihre Nachricht erhalten und kontaktieren Sie so zeitnah wie möglich.
        </Alert>
      )}
      {showFailure && (
        <Alert severity="error">
          Fehler! Wir haben Ihre Nachricht leider nicht erhalten. Es liegt eine technische Störung vor. Bitte
          kontaktieren Sie uns direkt über verwaltung@bdg-wohnbau.de
        </Alert>
      )}
      <Grid container spacing={3} sx={{ margin: "1em 0em 0em 0em" }}>
        <Grid item xs={12} style={{ paddingLeft: "0px" }}>
          <FormControl sx={{ width: props.isSmallScreen ? "95%" : "50%" }}>
            <Typography sx={{ color: "black" }}>Bitte geben Sie Ihren vollständigen Namen ein:</Typography>
            <Input
              required
              inputRef={nameRef}
              id="input-with-icon-adornment"
              inputProps={{ style: { color: "black" } }}
              sx={{ backgroundColor: "rgba(100,100,100,0.1)" }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "0px" }}>
          <FormControl sx={{ width: props.isSmallScreen ? "95%" : "50%" }}>
            <Typography sx={{ color: "black" }}>Bitte tragen Sie Ihre E-Mail Adresse ein:</Typography>
            <Input
              required
              inputRef={eMailRef}
              id="input-with-icon-adornment"
              sx={{ backgroundColor: "rgba(100,100,100,0.1)" }}
              inputProps={{ style: { color: "black" } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "0px" }}>
          <FormControl sx={{ width: props.isSmallScreen ? "95%" : "50%" }}>
            <Typography sx={{ color: "black" }}>
              <b>(optional)</b> Bitte Tragen Sie Ihre Tel. Nummer ein:
            </Typography>
            <Input
              inputRef={telRef}
              id="input-with-icon-adornment"
              sx={{ backgroundColor: "rgba(100,100,100,0.1)" }}
              inputProps={{ style: { color: "black" } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "0px" }}>
          <FormControl sx={{ width: props.isSmallScreen ? "95%" : "90%" }}>
            <Typography sx={{ color: "black" }}>Bitte beschreiben Sie Ihre Anfrage:</Typography>
            <Input
              inputRef={textRef}
              required
              fullWidth
              multiline
              rows={8}
              id="input-with-icon-adornment"
              sx={{ backgroundColor: "rgba(100,100,100,0.1)" }}
              inputProps={{ style: { color: "black" } }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" endIcon={<SendIcon />} onClick={handleSendClick} style={{ left: "-25px" }}>
            Senden
          </Button>
        </Grid>
      </Grid>

      {/* Consent Dialog */}
      <Dialog open={showConsentDialog} onClose={handleConsentCancel}>
        <DialogTitle>Einwilligung zur Datenverarbeitung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Mit dem Absenden des Formulars erklären Sie sich einverstanden, dass Ihre angegebenen Daten zur Bearbeitung
            Ihrer Anfrage verwendet werden. Ihre Daten werden vertraulich behandelt und nicht an Dritte weitergegeben.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConsentCancel} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleConsentAgree} color="primary" autoFocus>
            Einverstanden
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
