import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../resources/images/internal/BDG-Logo-New-Wide-White-Transparent.png';

const pages = ['Mission', 'Projekte', 'Team', 'Kontakt', 'Impressum'];

const ResponsiveAppBar = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 600);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const scrollWithOffset = (ref) => {
        const navbarHeight = (document.querySelector("nav")?.offsetHeight || 80) + 25; // Adjust with -10 or any value
        const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY - navbarHeight;
        window.scrollTo({
            top: elementPosition,
            behavior: "smooth",
        });
    };


        React.useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
        }, []);


    const handleScroll = (page) => {
        switch (page) {
            case "Mission":
                if (location.pathname !== "/") {
                    navigate("/");
                    setTimeout(() => scrollWithOffset(props.missionRef), 500);
                } else {
                    scrollWithOffset(props.missionRef);
                }
                break;
            case "Projekte":
                if (location.pathname !== "/") {
                    navigate("/");
                    setTimeout(() => scrollWithOffset(props.projectsRef), 500);
                } else {
                    scrollWithOffset(props.projectsRef);
                }
                break;
            case "Team":
                if (location.pathname !== "/") {
                    navigate("/");
                    setTimeout(() => scrollWithOffset(props.teamRef), 500);
                } else {
                    scrollWithOffset(props.teamRef);
                }
                break;
            case "Kontakt":
                if (location.pathname !== "/") {
                    navigate("/");
                    setTimeout(() => scrollWithOffset(props.contactRef), 500);
                } else {
                    scrollWithOffset(props.contactRef);
                }
                break;
            case "Impressum":
                navigate("/Impressum");
                setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                break;
            default:
                break;
        }
        handleCloseNavMenu();
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                bgcolor: "white",
                borderBottom: "3px solid grey",
                zIndex: 1300,
                top: 0,
                width: "100%",
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img 
                        src={Logo} 
                        style={{ 
                            maxHeight: isSmallScreen ? 70 : 80, // Scale the logo for smaller screens
                            marginRight: "2vw", 
                            maxWidth: "100%", // Ensure it scales within container
                            objectFit: "contain" 
                        }} 
                        alt="Logo" 
                        />
                    {/* Mobile Menu Icon */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="open menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon style={{ color: "black" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => handleScroll(page)}>
                                    <Button
                                        key={page}
                                        onClick={() => handleScroll(page)}
                                        sx={{ my: 2, color: "black", display: "block", fontSize: "12pt" }}
                                    >
                                        {page}
                                    </Button>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/* Desktop Menu */}
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleScroll(page)}
                                sx={{ my: 2, color: "black", display: "block", fontSize: "12pt" }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
